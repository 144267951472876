import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from "../config/routeConfig";
import ScrollToTop from "../components/shared-components/ScrollToTop";

export default function Content() {
  return (
    <div>
      <Suspense>
        <Router>
          <ScrollToTop />
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}
