import React from "react";
import "./i18n/config";
import MainStepper from "./app/layout/MainStepper";
import Header from "./app/layout/Header";
import ContentWrapper from "./app/layout/ContentWrapper";
import Content from "./app/layout/Content";
import { AppProvider } from "./state/AppContext";
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <AppProvider>
      <Header />
      <Toaster position="top-center" reverseOrder={false} />
      <MainStepper />
      <ContentWrapper>
        <Content />
      </ContentWrapper>
    </AppProvider>
  );
}
