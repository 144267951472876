import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../state/AppContext";

const options = [
  {
    label: "English",
    value: "en",
    image: "/eng.png",
  },
  {
    label: "Amharic",
    value: "am",
    image: "/amh.png",
  },
  {
    label: "Chinese (Simplified)",
    value: "zh",
    image: "/zh.png",
  },
  {
    label: "French",
    value: "fr",
    image: "/fr.png",
  },
  {
    label: "Korean",
    value: "ko",
    image: "/ko.png",
  },
  {
    label: "Spanish",
    value: "es",
    image: "/ar.png",
  },
  {
    label: "Vietnamese",
    value: "vi",
    image: "/vie.png",
  },
];

const LanguageDropdown = ({ options }) => {
  const { i18n } = useTranslation();
  const { state, updateState } = useContext(AppContext);

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
    updateState({ language: e.target.value });
  };

  return (
    <>
      <select
        name="languages"
        className="languageDropdown form-select form-select-sm"
        onChange={changeLanguage}
        aria-label="language-select"
        value={state.language}
      >
        {options.map((option, index) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

const LanguageSelect = () => {
  const { t } = useTranslation();
  return (
    <div className="languageDropdownContainer">
      <p>{t("LANGUAGE_SELECTION")}</p>
      <LanguageDropdown options={options} />
    </div>
  );
};

export default LanguageSelect;
