import React from "react";
import LanguageSelect from "../components/shared-components/LanguageSelect";

export default function Header() {
  return (
    <div className="header">
      <div className="logo-con">
        <img
          src="/assets/v1/img/dhs-logo.webp"
          alt="logo"
          width="161px"
          height="77px"
          loading="lazy"
        />
      </div>
      <div>
        <LanguageSelect />{" "}
      </div>
    </div>
  );
}
